<template>
    <div
        class="default-layout"
        tabindex="-1"
        ref="focusTarget"
        v-test:defaultLayout
    >
        <skip-links />

        <app-nav-bar />

        <main
            id="main-content"
            class="main-content"
            tabindex="-1"
            v-test:mainContent
        >
            <nuxt />
        </main>

        <!-- Transition the footer with the page content -->
        <transition
            name="page"
            mode="out-in"
        >
            <app-footer
                :key="$route.path"
                id="site-footer"
                tabindex="-1"
            />
        </transition>
        <transition
            name="fade"
            mode="out-in"
        >
            <lottie-animation
                v-if="animationData"
                v-show="states.loading"
                aria-hidden="true"
                class="lottie"
                :animation-data="animationData"
                loop
                v-test:loadingAnimation
            />
        </transition>
    </div>
</template>

<script>
    import AppNavBar from '../../organisms/AppNavBar/AppNavBar';
    import AppFooter from '../../molecules/AppFooter/AppFooter';
    import SkipLinks from '../../ui/SkipLinks/SkipLinks';
    import VueSetTimeout from '@netsells/vue-set-timeout';

    //eslint-disable-next-line @netsells/require-jsdoc-except/require-jsdoc
    const LottieAnimation = () => import('lottie-web-vue');

    export default {
        name: 'default-layout',

        components: { AppFooter, AppNavBar, SkipLinks, LottieAnimation },

        mixins: [
            VueSetTimeout,
        ],

        data() {
            return {
                animationData: null,
                states: {
                    loading: false,
                },
            };
        },

        watch: {
            /**
             * Reset focus to top of page if no hash and update loading state for lottie animation.
             *
             * @param {object} to
             */
            async $route(to) {
                this.states.loading = true;

                if (to.hash) {
                    return;
                }

                await this.$nextTick();

                this.setTimeout(() => { // reduce the flash of old page content
                    this.focusToTop();
                }, 50);

                this.setTimeout(() => { // stop showing loading
                    this.states.loading = false;
                }, 1000);
            },

            /**
             * When the loading state is set, load the lottie svg animation data.
             *
             * @param {boolean} val
             */
            'states.loading'(val) {
                if (!this.animationData && val) {
                    this.loadSvg();
                }
            },
        },

        methods: {
            /**
             * Set focus to top of page.
             */
            focusToTop() {
                const focusTarget = this.$refs.focusTarget;

                if (!focusTarget) {
                    return;
                }

                focusTarget.focus({
                    preventScroll: true,
                });
            },

            /**
             * Load the lottie animation file asynchronously.
             */
            async loadSvg() {
                const { default: svg } = await import('../../../lib/lottie-butterfly-page');

                this.animationData = svg;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .page-enter-active,
    .page-leave-active {
        transition: 0.5s;
    }

    .page-enter,
    .page-leave-to {
        opacity: 0;
    }

    .page-leave-to {
        transform: translateY(10px);
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: 0.5s;
    }

    .fade-enter,
    .fade-leave-to {
        opacity: 0;
        transform: translateY(5px);
    }

    .app-nav-bar.app-nav-bar {
        position: fixed;
        z-index: $zindex-fixed;
    }

    .main-content {
        padding-top: 80px;
    }

    .lottie {
        position: fixed;
        top: 50%;
        left: 50%;
        margin-left: -75px;
        margin-top: -75px;
        width: 150px;
        height: 150px;
        z-index: $zindex-tooltip;
    }
</style>
