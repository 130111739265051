import { waitUntil } from '../../.storybook/helpers';

/**
 * Insert a script into the dom with async.
 *
 * @param {string} src
 */
const insertScript = (src) => {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;

    document.body.appendChild(script);
};

export default async () => {
    try {
        insertScript('https://tracking.atreemo.com/Scripts/TrackingInit.js');

        const AtreemoTrackingLbr = await waitUntil(() => window.AtreemoTrackingLbr);

        AtreemoTrackingLbr.init([
            'Itsu, https://itsutools.itsucomms.co.uk/WebEcasts',
        ]);

        insertScript('https://tracking.atreemo.com/Scripts/Tracking.js');
    } catch (e) {
        //eslint-disable-next-line no-console
        console.error('could not init atreemo.');
    }
};
