export default {
    /**
     * Fetch the snippets data from the api.
     *
     * @param {object} context
     * @param {Function} context.commit
     */
    async fetchSnippets({ commit }) {
        const { default: snippetsQuery } = await import('../../queries/snippets.graphql');

        const {
            snippets: {
                data: snippets,
            },
        } = await this.$strapi.query(snippetsQuery);

        const { formatSnippets } = await import('../../lib/snippets');

        commit('setSnippets', await formatSnippets.call(this, snippets));
    },
};
