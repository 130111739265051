export default {
    /**
     * Toggle the mobile menu visibility.
     *
     * @param {object} state
     */
    toggleMobileMenuVisible(state) {
        state.mobileMenuVisible = !state.mobileMenuVisible;
    },

    /**
     * Set the navbar menu in the state.
     *
     * @param {object} state
     * @param {Array} data
     */
    setHeaderNavigation(state, data = []) {
        state.headerNavigation = data;
    },

    /**
     * Set the footer menu in the state.
     *
     * @param {object} state
     * @param {object} data
     */
    setFooterMainNavigation(state, data) {
        state.footerMainNavigation = data;
    },

    /**
     * Set the footer menu in the state.
     *
     * @param {object} state
     * @param {object} data
     */
    setFooterLinksNavigation(state, data = []) {
        state.footerLinksNavigation = data;
    },
};
