<template>
    <div class="itsu-errors">
        <b-alert
            show
            variant="danger"
        >
            <p
                v-for="error in displayedErrors"
                :key="error"
                v-test:error
            >
                {{ error }}
            </p>
        </b-alert>
    </div>
</template>

<script>
    import { BAlert } from 'bootstrap-vue';

    export default {
        name: 'itsu-errors',

        components: { BAlert },

        props: {
            errors: {
                type: [Array, String],
                required: true,
            },
        },

        computed: {
            /**
             * Always return an array of errors.
             *
             * @returns {string[]}
             */
            displayedErrors() {
                let errors = this.errors;

                if (!Array.isArray(errors)) {
                    errors = [errors];
                }

                return errors;
            },
        },
    };
</script>

<style lang="scss" scoped>
    ::v-deep {
        @import "~bootstrap/scss/alert";
    }

    .itsu-errors {
        font-size: rem(14px);
    }

    ::v-deep p {
        margin-bottom: rem(5px);

        &:last-child {
            margin-bottom: 0;
        }
    }
</style>
