/**
 * Check if the current environment is storybook.
 *
 * @returns {boolean}
 */
const isStorybook = () => {
    return (typeof window !== 'undefined') && Boolean(window.STORYBOOK_ENV);
};

export default isStorybook;
