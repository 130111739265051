import Vue from 'vue';

import {
  BCol,
  BRow,
  BContainer,
  BButton
} from 'bootstrap-vue';

Vue.component('BCol', BCol);
Vue.component('BRow', BRow);
Vue.component('BContainer', BContainer);
Vue.component('BButton', BButton);
