<template>
    <b-button
        @click="toggleMobileMenu"
        class="mobile-nav-button"
        v-test:navButton
    >
        <span class="sr-only">
            Toggle the menu
        </span>
        <app-icon
            :key="mobileMenuVisible"
            :name="mobileMenuVisible ? 'close' : 'menu'"
        />
    </b-button>
</template>

<script>
    import { mapState, mapActions } from 'vuex';

    export default {
        name: 'mobile-nav-button',

        data() {
            return {
                currentScroll: 0,
            };
        },

        computed: {
            ...mapState('navigation', [
                'mobileMenuVisible',
            ]),
        },

        methods: {
            ...mapActions('navigation', [
                'toggleMobileMenu',
            ]),
        },
    };
</script>

<style lang="scss" scoped>
    .mobile-nav-button {
        background-color: secondary-palette(8);
        border: none;
        min-width: auto;

        svg {
            fill: primary-palette(1);
            height: 24px;
            width: 24px;
        }
    }

    .btn.btn-secondary:not(:disabled):not(.disabled):focus {
        background-color: transparent;
    }
</style>
