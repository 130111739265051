import Vue from 'vue';
import LazyImage from '../components/ui/LazyImage/LazyImage';
import LazyBackground from '../components/ui/LazyBackground/LazyBackground';
import AppIcon from '../components/ui/AppIcon/AppIcon';
import MobileGutter from '../components/utils/MobileGutter/MobileGutter';

/* eslint-disable @netsells/require-jsdoc-except/require-jsdoc */
const FadeAnimation = () => import('../components/atoms/animations/FadeAnimation/FadeAnimation');
const FadeInwardsAnimation = () => import('../components/atoms/animations/FadeInwardsAnimation/FadeInwardsAnimation');
const FadeLeftAnimation = () => import('../components/atoms/animations/FadeLeftAnimation/FadeLeftAnimation');
const FadeRightAnimation = () => import('../components/atoms/animations/FadeRightAnimation/FadeRightAnimation');
const FadeUpAnimation = () => import('../components/atoms/animations/FadeUpAnimation/FadeUpAnimation');
const StaggerFadeHorizontalAnimation = () => import('../components/atoms/animations/StaggerFadeHorizontalAnimation/StaggerFadeHorizontalAnimation');
/* eslint-enable @netsells/require-jsdoc-except/require-jsdoc */

// Load any globally required components here
Object.entries({
    AppIcon,
    LazyImage,
    LazyBackground,
    MobileGutter,
    FadeAnimation,
    FadeInwardsAnimation,
    FadeLeftAnimation,
    FadeRightAnimation,
    FadeUpAnimation,
    StaggerFadeHorizontalAnimation,
}).forEach(([name, component]) => {
    Vue.component(name, component);
});
