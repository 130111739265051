import get from 'lodash/get';
import Vue from 'vue';

/**
 * Return a value for the given attribute.
 *
 * @param  {...any} args
 * @returns {object|string|number}
 */
const attr = function(...args) {
    let context = this.hasOwnProperty('page')
        ? this.page
        : {};

    let [path, fallback] = args;

    if (typeof args[0] === 'object') {
        context = args[0];
        path = args[1];
        fallback = args[2];
    }

    return get(context, path, fallback);
};

Vue.prototype.$attr = attr;
