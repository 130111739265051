export default {
    /**
     * Fetch an atreemo survey from the api.
     *
     * @param {object} context
     * @param {number|string} id
     *
     * @returns {Promise<object>}
     */
    getSurvey(context, id) {
        return this.$axios.$get(`api/atreemo/surveys/${ id }`, {
            progress: false,
        });
    },

    /**
     * Submit an atreemo survey response to the api.
     *
     * @param {object} context
     * @param {object} payload
     * @param {number|string} payload.id
     * @param {FormData} payload.formData
     *
     * @returns {Promise<object>}
     */
    submitSurvey(context, { id, formData }) {
        return this.$axios.$post(`api/atreemo/surveys/${ id }`, formData);
    },

    /**
     * Submit the landing page form data to the api.
     *
     * @param {object} context
     * @param {object} formData
     *
     * @returns {Promise<any>}
     */
    submitLandingPageForm(context, formData) {
        return this.$axios.$post('api/atreemo/landing-page-form', formData);
    },
};
