import consola from 'consola';

export const cache = {};

const logger = consola.create({
    defaults: {
        badge: true,
        type: 'pages',
    },
});

/**
 * Create the query function.
 *
 * @param {object} gql
 * @param {object} variables
 *
 * @returns {Promise<void>}
 */
function query(
    gql,
    variables = {},
) {
    const forceClear = this.route.query.cache === 'clear';

    /**
     * Get the current time.
     *
     * @returns {number}
     */
    const now = () => Math.round(new Date().getTime() / 1000);

    /**
     * Work out the cache timeout.
     *
     * @returns {number}
     */
    const generateCacheTimeout = () => now() + 3600;

    let site = this.$config.SITE_OVERRIDE;

    if (!site) {
        site = this.req
            ? this.req.headers.host
                .replace(/https?:\/\//, '')
            : window.location.host;
    }

    const params = {
        locale: 'en',
        site,
    };

    variables = {
        ...params,
        ...variables,
    };

    const key = [
        JSON.stringify(gql),
        JSON.stringify(variables),
    ].join(':');

    const cachedEntry = cache[key];

    /**
     * Perform the request and return the data.
     *
     * @returns {any}
     */
    const request = () => {
        return this.$graphql.strapi
            .request(gql, variables)
            .then((data) => {
                cache[key] = {
                    timestamp: generateCacheTimeout(),
                    data,
                };

                return data;
            })
            .catch((e) => {
                this.$sentry.captureException(e, {
                    extra: {
                        path: this.route.fullPath,
                        hasExistingCache: Boolean(cache[key]),
                    },
                });

                if (cache[key]) {
                    // If the cache is already populated for the current key,
                    // return it so we don't throw an error on the page
                    return cache[key].data;
                }

                throw e;
            });
    };

    if (!cachedEntry || forceClear || !cachedEntry.timestamp || cachedEntry.timestamp <= now()) {
        if (forceClear) {
            logger.log(`Cache clear was forced: [${ this.route.fullPath }]`);
        } else if (!cachedEntry) {
            logger.log(`Populating cache for the first time: [${ this.route.fullPath }]`);
        } else if (cachedEntry.timestamp <= now()) {
            logger.log(`Cache expired, fetching... [${ this.route.fullPath }]`);
        }

        return request();
    }

    if (process.server) {
        request();
    }

    return Promise.resolve(cachedEntry.data);
}

/**
 * Fetch a dynamic page from the api.
 *
 * @param {string} uri
 * @param {string} options
 * @param {number} [options.previewId]
 * @param {string} [options.locale]
 *
 * @returns {Promise<object>}
 */
async function fetchDynamicPage(uri, {
    previewId = undefined,
    locale = 'en',
} = {}) {
    const { default: dynamicPage } = previewId
        ? await import('../queries/dynamic-page-preview.graphql')
        : await import('../queries/dynamic-page.graphql');

    const {
        redirects: {
            data: [redirect],
        },
        pages: {
            data: [page],
        },
    } = await query.call(this, dynamicPage, {
        path: uri,
        uri,
        previewId,
        locale,
    });

    if (redirect) {
        const status = redirect.attributes.type === 'Temporary'
            ? 302
            : 301;

        this.redirect(status, redirect.attributes.to);

        return;
    }

    if (!page) {
        this.error({
            message: 'Page not found',
            statusCode: 404,
        });

        return;
    }

    return page;
}

/**
 * Setup the plugin.
 *
 * @param {object} context
 * @param {object} context.app
 * @param {object} context.$config
 * @param {object} context.route
 * @param {Function} inject
 *
 * @returns {Promise<void>}
 */
export default async function(context, inject) {
    const strapi = {
        query: query.bind(context),
        fetchDynamicPage: fetchDynamicPage.bind(context),
    };

    context.$strapi = strapi;
    inject('strapi', strapi);
};
