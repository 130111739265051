import Vue from 'vue';

Vue.prototype.$waitFor = (time) => new Promise((resolve) => setTimeout(resolve, time));

/**
 * Return a promise that doesn't resolve until the condition is met.
 *
 * Returns the result of that condition when resolves.
 *
 * @param {Function} condition
 *
 * @returns {Promise<any>}
 */
export const waitUntil = (condition) => new Promise((resolve) => {
    setTimeout(() => {
        if (condition()) {
            return resolve(condition);
        }

        const promise = setInterval(() => {
            if (!condition()) {
                return;
            }

            clearInterval(promise);
            resolve(condition());
        }, 100);
    }, 0);
});

Vue.prototype.$waitUntil = waitUntil;

/**
 * Determine if we're in a screenshot environment (puppeteer).
 * This is better than the storycap implementation as it's
 * accessible before any storybook setup is run.
 *
 * @returns {boolean}
 */
export const isScreenshot = () => typeof navigator !== 'undefined' && navigator.webdriver;

/**
 * Easily mock async data by providing initial data as a mixin.
 *
 * @param {object} story
 * @param {object} story.components
 * @param {Function} story.asyncData
 * @param {object} story.story
 *
 * @returns {object}
 */
export const mockAsyncData = ({ components, asyncData, ...story }) => {
    const [componentName, component] = Object.entries(components)[0];

    return {
        components: {
            ...components,
            [componentName]: {
                ...component,
                mixins: [
                    ...component.mixins || [],
                    {
                        data: asyncData,
                    },
                ],
            },
        },
        ...story,
    };
};

/**
 * Return a copy of the fixture without any reference to the original.
 *
 * @param {any} fixture
 *
 * @returns {any}
 */
export const cloneFixture = (fixture) => JSON.parse(JSON.stringify(fixture));

/**
 * Pull in all the arg keys except any specified.
 *
 * @param {object} args
 * @param {Array} except
 *
 * @returns {string[]}
 */
export const argsKeys = (args, except = []) => Object.keys(args)
    .filter((val) => !except.includes(val));
