<template>
    <nav class="app-navigation" aria-label="Main navigation">
        <ul class="menu-list list-unstyled">
            <li
                v-for="item in navigationItems"
                :key="item.text"
                class="menu-item"
                v-test:menuItem
            >
                <b-link
                    v-bind="item.props"
                    class="nav-link"
                    :tabindex="tabindex"
                    v-test:link
                >
                    <span
                        v-for="(segment, i) in item.textSegments"
                        :key="`${ segment.text }-${ i }`"
                        :class="segment.className"
                        v-test:segment
                    >{{ segment.text }}</span>
                </b-link>
            </li>
        </ul>
    </nav>
</template>

<script>
    import { BLink } from 'bootstrap-vue';
    import { mapState } from 'vuex';

    export default {
        name: 'app-navigation',

        components: { BLink },

        data() {
            return {
                tabindex: 0,
            };
        },

        computed: {
            ...mapState('navigation', ['headerNavigation', 'mobileMenuVisible']),

            /**
             * Return the nav items with text segments to enable us
             * to highlight text in and including square brackets.
             *
             * @returns {{props: object, textSegments: {text: string, className: string}[]}[]}
             */
            navigationItems() {
                return this.headerNavigation.items.map((item) => ({
                    textSegments: item.text
                        .split(/(\[|\])/)
                        .filter((string) => string.trim())
                        .map((string) => ({
                            text: string,
                            className: /\[|]/g.test(string)
                                ? 'highlighted'
                                : '',
                        })),
                    props: item.props,
                }));
            },
        },

        watch: {
            /**
             * Watch if menu opens so can update tabindex.
             *
             * @param {boolean} newValue
             */
            mobileMenuVisible(newValue) {
                if (newValue) {
                    this.tabindex = 0;
                } else {
                    this.tabindex = -1;
                }
            },
        },

        mounted() {
            this.getTabindex(window.innerWidth);
        },

        methods: {
            /**
             * Determine tabindex value for links.
             *
             * @param {number} width
             */
            getTabindex(width) {
                if (!process.client) {
                    return;
                }
                if (width < 992 && !this.mobileMenuVisible) {
                    this.tabindex = -1;
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .app-navigation {
        width: 100%;
        background-color: secondary-palette(8);
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 20px 40px 40px;
        overscroll-behavior: contain;
        @include media-breakpoint-up(lg) {
            padding: 0;
        }
    }

    .menu-list {
        @include media-breakpoint-up(lg) {
            display: flex;
            justify-content: center;
            margin-bottom: 0;
        }
    }

    .nav-link {
        padding: 15px 0;
        color: $black;
        font-weight: bold;
        display: block;
        @include media-breakpoint-up(lg) {
            padding: 0 10px;
        }

        &:hover,
        &:focus-visible,
        &:active {
            color: primary-palette(1);
            text-decoration: none;
            font-weight: bold;
        }

        &.nuxt-link-exact-active {
            @include media-breakpoint-down(md) {
                color: primary-palette(1);
            }
        }
    }

    .highlighted {
        color: primary-palette(1);
        font-weight: 400;
    }
</style>
