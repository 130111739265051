<template>
    <div class="mobile-gutter">
        <slot />
    </div>
</template>

<script>
    export default {
        name: 'mobile-gutter',
    };
</script>

<style lang="scss" scoped>
    .mobile-gutter {
        padding: 0 20px;

        @include media-breakpoint-up(md) {
            padding: 0;
        }
    }
</style>
