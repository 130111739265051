export default {
    /**
     * Fetch all recipes from the API.
     *
     * @param {object} context
     * @param {object} payload
     * @param {boolean} payload.includeCategories
     * @param {string[]} payload.categories
     *
     * @returns {Promise<object>}
     */
    async fetchRecipes(context, {
        includeCategories = true,
        categories,
    } = {}) {
        const filters = {
            page: {
                id: {
                    notNull: true,
                },
            },
        };

        if (categories?.length) {
            filters.and = categories.map((category) => ({
                recipe_categories: {
                    slug: {
                        contains: category,
                    },
                },
            }));
        }

        const { default: query } = await import('../../queries/recipes.graphql');

        return this.$strapi.query(query, {
            includeCategories,
            filters,
        });
    },
};
