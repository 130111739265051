<template>
    <div class="error-page">
        <patterned-hero-section
            :title="errorMessage"
        >
            <p>
                perhaps you could
                <a :href="$route.fullPath" v-test:reloadLink>try again</a>,
                or return to the
                <a href="/" v-test:homepageLink>homepage?</a>
            </p>
        </patterned-hero-section>
    </div>
</template>

<script>
    import PatternedHeroSection from '../../organisms/PatternedHeroSection/PatternedHeroSection';

    export default {
        name: 'error-page',

        components: { PatternedHeroSection },

        props: {
            error: {
                type: Object,
                default: () => ({}),
            },
        },

        computed: {
            /**
             * Return the error message copy.
             *
             * @returns {string}
             */
            errorMessage() {
                if (this.error?.statusCode === 404) {
                    return '404 - the page you requested could not be found';
                }

                return 'sorry, there was a problem loading the page you requested';
            },
        },
    };
</script>
