export const actions = {
    /**
     * Fetch data on SSR page load.
     *
     * @param {object} store
     * @param {Function} store.dispatch
     * @param {object} context
     * @param {Function} context.error
     *
     * @returns {Promise<void>}
     */
    async nuxtServerInit({ dispatch }, { error }) {
        try {
            await Promise.all([
                dispatch('locales/fetchLocales'),
                dispatch('navigation/fetchNavigations'),
                dispatch('snippets/fetchSnippets'),
                dispatch('user/fetchLocationFromCookie'),
            ]);
        } catch (e) {
            if (typeof jest === 'undefined') {
                //eslint-disable-next-line no-console
                console.dir({ ...e }, { depth: null });
            }

            error({
                statusCode: 500,
            });
        }
    },
};
