<template>
    <input-wrapper
        :class="`${ type }-input`"
        v-bind="{ label, state, helpText }"
    >
        <template #default="{ id }">
            <component :is="$slots.button ? 'b-input-group' : 'div'">
                <b-form-input
                    v-bind="{ state, name, id, type, placeholder, required, autocomplete }"
                    v-model="inputValue"
                />
                <b-input-group-append v-if="$slots.button">
                    <slot name="button" />
                </b-input-group-append>
                <b-form-invalid-feedback
                    v-for="(error, i) in errors"
                    :key="i"
                    :state="state"
                    class="input-error"
                    v-test:inputErrorFeedback
                    aria-live="polite"
                >
                    <warning-icon class="warning-icon" />
                    {{ error }}
                </b-form-invalid-feedback>
            </component>
        </template>
    </input-wrapper>
</template>

<script>
    import InputWrapper from '../InputWrapper/InputWrapper';
    import { BFormInput, BInputGroup, BInputGroupAppend, BFormInvalidFeedback } from 'bootstrap-vue';
    import InputBase from '../InputBase.mixin';
    import WarningIcon from '../../../../../resources/assets/icons/svgs/warning.svg?inline';

    export default {
        name: 'text-input',

        components: {
            InputWrapper,
            BFormInput,
            WarningIcon,
            BInputGroup,
            BInputGroupAppend,
            BFormInvalidFeedback,
        },

        mixins: [InputBase],

        computed: {
            /**
             * Return the input type.
             * This allows for this component to be reused for similar inputs.
             *
             * @returns {string}
             */
            type() {
                return 'text';
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import "~bootstrap/scss/input-group";
    @import "~bootstrap/scss/forms";

    .form-control {
        &.is-invalid:focus {
            box-shadow: none;
            border-color: primary-palette(1);
        }

        &.is-valid:focus {
            box-shadow: none;
            border-color: primary-palette(1);
        }
    }

    .form-control::placeholder {
        font-weight: 300;
    }

    .input-error {
        &.invalid-feedback {
            display: flex;
            align-items: center;
            padding-left: 10px;
            color: primary-palette(2);
        }

        svg {
            width: 22px;
            height: 22px;
            fill: primary-palette(1);
            margin-right: 5px;
        }
    }
</style>
