export default {
    /**
     * Set the snippets on the state.
     *
     * @param {object} state
     * @param {Array} snippets
     */
    setSnippets(state, snippets) {
        Object.assign(state, snippets);
    },
};
