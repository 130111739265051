<template>
    <fade-up-animation :element="() => $refs.section">
        <header
            class="app-nav-bar"
            :class="{ 'mobile-menu-open': mobileMenuVisible }"
            v-test:appNavBar
            ref="section"
        >
            <nuxt-link to="/" class="brand-logo">
                <span
                    id="itsu-logo-text"
                    class="sr-only"
                >
                    Go to the homepage
                </span>
                <app-logo />
            </nuxt-link>

            <mobile-nav-button
                aria-haspopup="true"
                aria-controls="app-navigation"
                :aria-expanded="mobileMenuVisible"
            />

            <app-navigation
                id="app-navigation"
                :class="{ 'mobile-menu-open': mobileMenuVisible }"
            />

            <transition name="fade" mode="out-in">
                <language-selection
                    v-if="pageLocales.length > 1"
                />
            </transition>

            <global-events
                @resize="handleWindowResize"
                target="window"
            />
        </header>
    </fade-up-animation>
</template>

<script>
    import AppLogo from '../../ui/AppLogo/AppLogo';
    import AppNavigation from '../../molecules/AppNavigation/AppNavigation';
    import MobileNavButton from '../../molecules/MobileNavButton/MobileNavButton';
    import LanguageSelection from '../../molecules/LanguageSelection/LanguageSelection';
    import { mapActions, mapState } from 'vuex';
    import GlobalEvents from 'vue-global-events';

    export default {
        name: 'app-nav-bar',

        components: { LanguageSelection, MobileNavButton, AppNavigation, AppLogo, GlobalEvents },

        computed: {
            ...mapState('navigation', [
                'mobileMenuVisible',
            ]),

            ...mapState('locales', [
                'pageLocales',
            ]),
        },

        watch: {
            /**
             * Close menu when navigating.
             */
            $route() {
                if (this.mobileMenuVisible) {
                    this.toggleMobileMenu();
                }
            },
        },

        methods: {
            ...mapActions('navigation', [
                'toggleMobileMenu',
            ]),

            /**
             * Handle window resize and reset mobileMenuVisible.
             */
            handleWindowResize() {
                if (this.mobileMenuVisible) {
                    this.toggleMobileMenu();
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    $desktop-breakpoint: 1060px;

    .app-nav-bar {
        display: flex;
        align-items: center;
        color: $black;
        background-color: secondary-palette(8);
        height: 80px;
        top: 0;
        left: 0;
        right: 0;
        z-index: $zindex-fixed;
        border-bottom: solid 2px #ffffff;
        padding-left: 95px;
        padding-right: 68px;
        justify-content: center;

        @media (min-width: $desktop-breakpoint) {
            border-bottom: 0;
            justify-content: flex-start;
        }

        &.mobile-menu-open {
            border-bottom-color: secondary-palette(7);
        }
    }

    .brand-logo {
        position: absolute;
        left: 20px;
        display: block;
        padding: 0 5px 5px;

        svg {
            width: auto;
            height: 50px;
        }
    }

    .app-navigation {
        left: 100%;
        position: fixed;
        top: 80px;
        height: calc(100vh - 80px);

        @include transitions-enabled {
            transition: transform 0.6s ease-in-out;
        }

        @media (min-width: $desktop-breakpoint) {
            position: unset;
            height: auto;
            top: unset;
        }

        &.mobile-menu-open {
            transform: translateX(-100%);
        }
    }

    .mobile-nav-button {
        position: absolute;
        right: 10px;

        @media (min-width: $desktop-breakpoint) {
            display: none;
        }
    }

    .language-selection {
        @media (min-width: $desktop-breakpoint) {
            position: absolute;
            transform: none;
            left: auto;
            right: 25px;
        }
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: 0.15s;
    }

    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
</style>
