export default {
    /**
     * Fetch the products and required data from the api.
     *
     * @param {object} context
     * @param {object} payload
     * @param {object} payload.filters
     * @param {boolean} payload.onlyFetchProducts
     *
     * @returns {Promise<{menuLinks: {name: string, to: string}[], categories: object[], products: object[]}>}
     */
    async fetchProducts(context, {
        filters = {},
        onlyFetchProducts = false,
    } = {}) {
        const queryFilters = {
            // By default we want to exclude projects that don't yet have a
            // page link, as they can't actually be linked to
            page: {
                id: {
                    notNull: true,
                },
            },
            // Merge the provided additional filters in
            ...filters,
        };

        // Import the combined query for fetching products for the product listing page
        const { default: productsQuery } = await import('../../queries/grocery/grocery-products-listing.graphql');

        // Run the query
        const {
            groceryProducts,
            groceryProductCategories,
            navigationGroups,
        } = await this.$strapi.query(productsQuery, {
            filters: queryFilters,
            onlyFetchProducts,
        });

        const {
            formatMenuLinks,
            formatProductCategories,
            formatProducts,
        } = await import('../../lib/products');

        // Return the formatted data
        return {
            menuLinks: formatMenuLinks(navigationGroups?.data?.[0]?.attributes?.navigation_items),
            categories: formatProductCategories(groceryProductCategories?.data),
            products: formatProducts(this, groceryProducts?.data),
        };
    },

    /**
     * Fetch the grocery categories.
     *
     * @returns {Promise<{text: string, to: string}[]>}
     */
    async fetchCategories() {
        // Import the combined query for fetching grocery product categories
        const { default: categoriesQuery } = await import('../../queries/grocery/grocery-product-categories.graphql');

        // Run the query
        const { groceryProductCategories } = await this.$strapi.query(categoriesQuery);

        const { formatProductCategories } = await import('../../lib/products');

        // Return the formatted data
        return formatProductCategories(groceryProductCategories?.data);
    },
};
