export default {
    /**
     * Restaurant the users selected location in the state.
     *
     * @param {object} state
     * @param {object} payload
     * @param {string|number} payload.lat
     * @param {string|number} payload.lng
     * @param {string} payload.location
     */
    setLocation(state, { lat, lng, location }) {
        state.lat = lat;
        state.lng = lng;
        state.location = location;

        this.$cookies.set('location_lat', lat);
        this.$cookies.set('location_lng', lng);
        this.$cookies.set('location', location);
    },
};
