<template>
    <component
        :is="tag"
        class="lazy-background"
        :style="{
            backgroundImage: states.loaded
                ? `url(${ transformedSrc })`
                : false,
        }"
    >
        <lazy-image
            :src="transformedSrc"
            :alt="alt"
            @load.native="states.loaded = true"
        />
        <slot />
    </component>
</template>

<script>
    import isStorybook from '../../../lib/is-storybook';

    export default {
        name: 'lazy-background',

        props: {
            src: {
                type: String,
                required: true,
            },

            alt: {
                type: String,
                required: true,
            },

            tag: {
                type: String,
                default: 'div',
            },
        },

        data() {
            return {
                states: {
                    loaded: false,
                },
            };
        },

        computed: {
            /**
             * Return the transformed src for the image provider.
             *
             * @returns {string}
             */
            transformedSrc() {
                let src = this.src;

                if (src.endsWith('.svg')) {
                    return src;
                }

                if (src.startsWith('/')) {
                    if (process.env.NODE_ENV !== 'production' || isStorybook()) {
                        return src;
                    }

                    src = `${ this.$config.APP_URL }${ src }`;
                }

                return this.$img(src);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .lazy-background {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .lazy-image {
        display: block;
        width: 0;
        height: 0;
    }
</style>
