export default {
    /**
     * Return the current page locales with the addition of
     * the name, which is stored in the `locales` state.
     *
     * @param {object} state
     *
     * @returns {{ code: string, name: string }[]}
     */
    pageLocalesWithName(state) {
        return state.pageLocales.map((locale) => ({
            code: locale,
            name: state.locales
                .find(({ code }) => code === locale)?.name,
        }));
    },
};
