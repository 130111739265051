export default (context, inject) => {
    /**
     * Snippet shortcut.
     *
     * @param {string} key
     * @param {string} fallback
     *
     * @returns {string}
     */
    const snippet = (key, fallback = '') => {
        return context.app.store.state.snippets[key]
            || fallback;
    };

    context.$snippet = snippet;
    inject('snippet', snippet);
};
