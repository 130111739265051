<template>
    <div class="app-form-input">
        <text-input
            :label="label"
            :name="name"
            :placeholder="placeholder"
            :errors="errors"
            :success="success"
            :disabled="disabled"
            :required="required"
            :autocomplete="autocomplete"
            v-model="valueProxy"
        >
            <template v-slot:button>
                <b-button
                    class="app-form-button"
                    type="submit"
                    :variant="variant"
                    :disabled="disabled"
                >
                    {{ buttonText }}
                </b-button>
            </template>
        </text-input>
    </div>
</template>

<script>
    import TextInput from '../../atoms/inputs/TextInput/TextInput';
    import createPropProxy from '../../../lib/prop-proxies';

    export default {
        name: 'app-form-input',

        components: { TextInput },

        props: {
            value: {
                type: String,
                default: '',
            },

            label: {
                type: String,
                default: '',
            },

            name: {
                type: String,
                default: '',
            },

            to: {
                type: String,
                default: '',
            },

            placeholder: {
                type: String,
                default: '',
            },

            buttonText: {
                type: String,
                default: '',
            },

            disabled: {
                type: Boolean,
                default: false,
            },

            required: {
                type: Boolean,
                default: false,
            },

            autocomplete: {
                type: String,
                default: null,
            },

            errors: {
                type: Array,
                default: () => ([]),
            },

            success: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            ...createPropProxy(['value']),

            /**
             * Get variant for button.
             *
             * @returns {string}
             */
            variant() {
                if (this.success) {
                    return 'success';
                }
                if (this.errors && this.errors.length) {
                    return 'warning';
                }
                return 'primary';
            },
        },
    };
</script>

<style lang="scss" scoped>
    ::v-deep .form-control.form-control {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        border: solid 1px secondary-palette(8);
        padding-left: rem(19px);
        padding-right: rem(19px);

        &:focus {
            border: solid 1px primary-palette(1);
        }
    }

    .text-input.text-input {
        margin: 0;
    }

    .app-form-button {
        border-radius: 0 20px 20px 0;
        padding: 0 35px;

        &:focus {
            box-shadow: none;
            background-color: primary-palette(1);
            border-color: primary-palette(1);
        }

        &:hover {
            background-color: rgba(primary-palette(1), 0.6);
            border-color: transparent;
        }

        &:focus-visible {
            border: solid 1px primary-palette(2);
        }

        &.btn-warning,
        &.btn-success,
        &.btn-primary {
            &:active {
                background-color: rgba(primary-palette(1), 0.6);
                border-color: rgba(primary-palette(1), 0.6);

                &:focus {
                    box-shadow: none;
                    color: secondary-palette(8);
                }
            }

            &:focus {
                border-color: primary-palette(1);
            }

            &.disabled {
                background-color: secondary-palette(6);
                border-color: secondary-palette(6);
            }
        }
    }

    ::v-deep {
        .btn-success, .btn-warning {
            background-color: primary-palette(1);
            color: secondary-palette(8);
        }
    }
</style>
