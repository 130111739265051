<template>
    <b-form-group
        class="input-wrapper"
        v-bind="{ state }"
    >
        <template #default="{ id }">
            <label
                v-if="label"
                :for="generateId(id)"
                class="label"
                v-test:label
                v-html="label"
            />
            <p v-if="helpText" v-test:helpText>
                {{ helpText }}
            </p>
            <div class="inner" v-test:inner>
                <slot
                    v-bind="{
                        id: generateId(id),
                    }"
                />
            </div>
        </template>
    </b-form-group>
</template>

<script>
    import { BFormGroup } from 'bootstrap-vue';

    export default {
        name: 'input-wrapper',

        components: { BFormGroup },

        props: {
            label: {
                type: String,
                default: '',
            },

            state: {
                type: Boolean,
                default: null,
            },

            helpText: {
                type: String,
                default: '',
            },
        },

        data() {
            return {
                id: null,
            };
        },

        methods: {
            /**
             * Generate an id for the input.
             *
             * @param {string} id
             *
             * @returns {string}
             */
            generateId(id) {
                this.id = `${ id }-input`;

                return this.id;
            },
        },
    };
</script>

<style lang="scss" scoped>
    ::v-deep {
        @import "~bootstrap/scss/forms";
    }

    .label {
        font-weight: 300;
        text-transform: lowercase;
        font-size: rem(13px);
        color: secondary-palette(5);
        line-height: rem(16px);
        margin-bottom: rem(5px);
    }
</style>
