export default {
    /**
     * Fetch all area locations from the API.
     *
     * @returns {object}
     */
    async getAllItsuLocations() {
        const { default: query } = await import('../../queries/all-itsu-locations.graphql');

        const { locationAreas } = await this.$strapi.query(query);

        const { formatRestaurantAreas } = await import('../../lib/restaurant-areas');

        return formatRestaurantAreas(locationAreas.data);
    },
};
