<template>
    <div
        class="markdown-content"
        v-html="renderedContent"
    />
</template>

<script>
    import DomListeners from '@netsells/vue-dom-listeners';
    import MarkdownIt from 'markdown-it';

    export default {
        name: 'markdown-content',

        mixins: [DomListeners],

        props: {
            content: {
                type: String,
                default: '',
            },
        },

        data() {
            return {
                md: null,
            };
        },

        computed: {
            /**
             * Render the markdown to html.
             *
             * @returns {string}
             */
            renderedContent() {
                return this.md
                    ?.render(this.content || '');
            },

            /**
             * Options object to be passed to the `markdown-it` package.
             *
             * @see https://github.com/markdown-it/markdown-it#init-with-presets-and-options
             *
             * @returns {{html: boolean}}
             */
            options() {
                return {
                    html: true,
                    breaks: true,
                };
            },
        },

        watch: {
            content: 'interceptLinks',
        },

        created() {
            this.md = new MarkdownIt(this.options);
        },

        mounted() {
            this.interceptLinks();
            this.tagExternalLinks();
        },

        methods: {
            /**
             * Intercept links in the markdown to do internal navigations.
             */
            interceptLinks() {
                const links = [...this.$el.querySelectorAll('a')]
                    .filter((el) => {
                        const href = el.getAttribute('href');

                        // Check if the href is from the root or is for the current domain
                        return href[0] === '/'
                            || href.startsWith(window.location.origin);
                    });

                // Add a click event listener to each link
                links.forEach((el) => {
                    this.addEventListener(el, 'click', (e) => {
                        if (e.metaKey) {
                            // Don't intercept cmd+click to open in new tab
                            return;
                        }

                        e.preventDefault();

                        const destination = el.getAttribute('href')
                            .replace(window.location.origin, '')
                            || '/';

                        this.$router.push(destination);
                    });
                });
            },

            /**
             * Add the external link tags such as `target="_blank"` and `rel="noopener"`.
             */
            tagExternalLinks() {
                const links = [...this.$el.querySelectorAll('a')]
                    .filter((el) => {
                        const href = el.getAttribute('href');

                        // Check if the href is from the root or is for the current domain
                        return !(
                            href[0] === '/'
                            || href.startsWith(window.location.origin)
                        );
                    });

                links.forEach((link) => {
                    link.setAttribute('target', '_blank');
                    link.setAttribute('rel', 'noopener');
                });
            },
        },
    };
</script>
