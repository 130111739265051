export default {
    /**
     * Sign the user up to the newsletter.
     *
     * @param {object} context
     * @param {object} data
     *
     * @returns {Promise<any>}
     */
    signup(context, data) {
        return this.$axios.$post('api/atreemo/newsletter', data);
    },

    /**
     * Update the users newsletter preferences.
     *
     * @param {object} context
     * @param {object} payload
     * @param {number|string} payload.id
     * @param {object} payload.data
     *
     * @returns {Promise<any>}
     */
    updatePreferences(context, { id, ...data }) {
        return this.$axios.$patch(`api/atreemo/newsletter/${ id }`, data);
    },

    /**
     * Fetch the users newsletter preferences.
     *
     * @param {object} context
     * @param {object} id
     *
     * @returns {Promise<any>}
     */
    fetchPreferences(context, id) {
        return this.$axios.$get(`api/atreemo/newsletter/${ id }`, {
            progress: false,
        });
    },
};
