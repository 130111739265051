export default ({ $sentry, $log, error }, inject) => {
    inject('handleError', (e, message) => {
        $sentry.captureException(e);
        $log(message, {
            exception: e,
        }, 'error');
        error({
            message: e.message,
            statusCode: e.status,
        });
    });
};
