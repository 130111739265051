<template>
    <component
        :is="useProvider ? 'nuxt-img' : 'img'"
        :src="transformedSrc"
        :alt="alt"
        :srcset="srcset"
        :width="width"
        :height="height"
        loading="lazy"
        class="lazy-image"
    />
</template>

<script>
    import isStorybook from '../../../lib/is-storybook';

    export default {
        name: 'lazy-image',

        props: {
            src: {
                type: String,
                required: true,
            },

            alt: {
                type: String,
                required: true,
            },

            srcset: {
                type: String,
                default: null,
            },

            width: {
                type: [Number, String],
                default: null,
            },

            height: {
                type: [Number, String],
                default: null,
            },
        },

        computed: {
            /**
             * Check whether to use the image provider.
             *
             * @returns {boolean}
             */
            useProvider() {
                return !this.transformedSrc?.startsWith('/') && !this.src?.endsWith('.svg');
            },

            /**
             * Return the transformed src for the image provider.
             *
             * @returns {string}
             */
            transformedSrc() {
                const blankPixel = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'; // Simple blank pixel gif
                let src = this.src || blankPixel;

                if (src.endsWith('.svg')) {
                    return src;
                }

                if (src.startsWith('/')) {
                    if (process.env.NODE_ENV !== 'production' || isStorybook()) {
                        return src;
                    }

                    src = `${ this.$config.APP_URL }${ src }`;
                }

                return this.$img(src);
            },
        },

        mounted() {
            if (this.$el.complete) {
                this.$el.dispatchEvent(new Event('load'));
            }
        },
    };
</script>
