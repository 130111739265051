export default {
    /**
     * Fetch all restaurants from the API.
     *
     * @returns {Promise<object>}
     */
    async getRestaurants() {
        const { default: query } = await import('../../queries/restaurants.graphql');

        return this.$strapi.query(query);
    },
};
