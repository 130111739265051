<template>
    <div class="footer-newsletter-signup">
        <b-row class="newsletter-inner-row">
            <b-col
                cols="12"
                lg="5"
                xl="12"
            >
                <div class="text-content">
                    <markdown-content
                        :content="$snippet('footer_text', '')"
                        v-test:textContent
                    />
                </div>
            </b-col>
            <b-col
                cols="12"
                lg="7"
                xl="12"
                class="app-form-input-column"
            >
                <newsletter-signup-form />
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import AppLogo from '../../ui/AppLogo/AppLogo';
    import MarkdownContent from '../../utils/MarkdownContent/MarkdownContent';
    import NewsletterSignupForm from '../NewsletterSignupForm/NewsletterSignupForm';

    export default {
        name: 'footer-newsletter-signup',

        components: { NewsletterSignupForm, MarkdownContent, AppLogo },
    };
</script>

<style lang="scss" scoped>
    .footer-newsletter-signup {
        @include media-breakpoint-up(xl) {
            padding-right: 120px;
        }
    }

    .newsletter-inner-row {
        align-items: flex-end;
    }

    .app-form-input-column {
        padding-top: 15px;

        @include media-breakpoint-only(lg) {
            padding-top: 0;
            padding-left: 30px;
        }
    }

    .text-content {
        font-weight: 300;
        font-size: rem(15px);

        ::v-deep p:last-child {
            margin-bottom: 0;
        }
    }

    .app-logo {
        width: 50px;
        height: 45px;
        margin-bottom: 20px;
    }
</style>
