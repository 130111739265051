export default {
    /**
     * Fetch all the configured locales from the api.
     *
     * @param {object} context
     *
     * @returns {Promise<void>}
     */
    async fetchLocales(context) {
        // Lazy load the query
        const { default: localesQuery } = await import('../../queries/locales.graphql');

        // Run the query
        const { i18NLocales } = await this.$strapi.query(localesQuery);

        context.commit('setLocales', i18NLocales.data.map(({ attributes }) => ({
            code: attributes.code,
            name: attributes.name,
        })));
    },
};
