export default {
    /**
     * Fetch the navigations from the api.
     *
     * @param {object} context
     * @param {Function} context.commit
     */
    async fetchNavigations({ commit }) {
        const { default: navigationsQuery } = await import('../../queries/navigations.graphql');

        const {
            header,
            footer_main,
            footer_links,
        } = await this.$strapi.query(navigationsQuery);

        const { default: formatNavigationData } = await import('../../utils/formatters/format-navigation-data');

        commit('setHeaderNavigation', formatNavigationData(header)[0]);
        commit('setFooterMainNavigation', formatNavigationData(footer_main));
        commit('setFooterLinksNavigation', formatNavigationData(footer_links)[0]);
    },

    /**
     * Set scroll position and toggle mobile menu.
     *
     * @param {object} context
     * @param {Function} context.commit
     * @param {Function} context.state
     */
    async toggleMobileMenu({ commit, state }) {
        // stop body scroll and maintain scroll position
        if (process.client) {
            if (!state.mobileMenuVisible) { // if opening
                document.body.style.overflow = 'hidden';
            } else { // if closing
                document.body.style.overflow = '';
            }
        }

        commit('toggleMobileMenuVisible');
    },
};
