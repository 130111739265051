export default ({ app, route }) => {
    const appUrl = app.$config.APP_URL;

    if (route.query['no-cookie-banner'] !== undefined) {
        return;
    }

    window._iub = window._iub || [];
    window._iub.csConfiguration = {
        askConsentAtCookiePolicyUpdate: true,
        floatingPreferencesButtonDisplay: false,
        lang: 'en-GB',
        perPurposeConsent: true,
        siteId: 3196620,
        cookiePolicyId: 67080392,
        banner: {
            acceptButtonCaption: 'accept',
            acceptButtonColor: '#E70095',
            acceptButtonDisplay: true,
            backgroundColor: '#FFFFFF',
            brandBackgroundColor: '#FFFFFF',
            brandTextColor: '#000000',
            closeButtonDisplay: false,
            customizeButtonCaptionColor: '#E70095',
            customizeButtonColor: '#F6F6F6',
            customizeButtonDisplay: true,
            linksColor: '#000000',
            position: 'float-bottom-right',
            rejectButtonCaption: 'reject',
            rejectButtonCaptionColor: '#E70095',
            rejectButtonColor: '#FFFFFF',
            rejectButtonDisplay: true,
            textColor: '#212529',
            content: 'our website uses cookies, not the miso caramel kind. they help us share content tailored for you. your privacy is important to us, so you can either accept or reject.',
            customizeButtonCaption: 'learn more and customise',
        },
        footer: {
            btnCaption: 'save and continue',
            message: 'by continuing to browse or by otherwise closing this window, you accept the current cookie settings.',
        },
        cookiePolicyUrl: `${ appUrl }/privacy-policy/?no-cookie-banner`,
        privacyPolicyUrl: `${ appUrl }/privacy-policy/?no-cookie-banner`,
        privacyPolicyNoticeAtCollectionUrl: `${ appUrl }/privacy-policy/?no-cookie-banner`,
        whitelabel: true,
        callback: {
            //eslint-disable-next-line @netsells/require-jsdoc-except/require-jsdoc
            onPreferenceExpressedOrNotNeeded(preference) {
                window.dataLayer.push({
                    iubenda_ccpa_opted_out: window._iub.cs.api.isCcpaOptedOut(),
                });

                if (!preference) {
                    window.dataLayer.push({
                        event: 'iubenda_preference_not_needed',
                    });

                    return;
                }

                if (preference.consent === true) {
                    dataLayer.push({
                        event: 'iubenda_consent_given',
                    });

                    return;
                }

                if (preference.consent === false) {
                    window.dataLayer.push({
                        event: 'iubenda_consent_rejected',
                    });

                    return;
                }

                if (preference.purposes) {
                    for (const purposeId in preference.purposes) {
                        if (preference.purposes[purposeId]) {
                            window.dataLayer.push({
                                event: `iubenda_consent_given_purpose_${ purposeId }`,
                            });
                        }
                    }
                }
            },
        },
    };

    const iubenda = document.createElement('script');
    iubenda.src = '//cdn.iubenda.com/cs/iubenda_cs.js';
    iubenda.type = 'text/javascript';
    iubenda.async = true;
    document.body.appendChild(iubenda);
};
