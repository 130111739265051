export default {
    /**
     * Fetch the itsu locations from atreemo.
     *
     * @returns {Promise<any>}
     */
    get() {
        return this.$axios.$get('api/atreemo/locations', {
            progress: false,
        });
    },
};
