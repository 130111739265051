export default {
    /**
     * Fetch the users location from the previously set cookie and set it in the store.
     *
     * @param {object} context
     * @param {Function} context.commit
     */
    fetchLocationFromCookie({ commit }) {
        const lat = this.$cookies.get('location_lat') || '';
        const lng = this.$cookies.get('location_lng') || '';
        const location = this.$cookies.get('location') || '';

        commit('setLocation', {
            lat,
            lng,
            location,
        });
    },
};
