<template>
    <component
        :is="`itsu-logo-${ variant }`"
        class="app-logo"
        focusable="false"
    />
</template>

<script>
    import ItsuLogoDark from '../../../assets/images/svgs/itsu-logo-dark.svg?inline';
    import ItsuLogoLight from '../../../assets/images/svgs/itsu-logo-light.svg?inline';

    export default {
        name: 'app-logo',

        components: {
            ItsuLogoDark,
            ItsuLogoLight,
        },

        props: {
            variant: {
                type: String,
                default: 'dark',
            },
        },
    };
</script>
