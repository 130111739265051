export default {
    props: {
        label: {
            type: String,
            required: false,
        },

        name: {
            type: String,
            required: true,
        },

        errors: {
            type: [String, Array],
            default: '',
        },

        value: {
            type: [
                String,
                Number,
                Date,
                Boolean,
                typeof File !== 'undefined'
                    ? File
                    : false,
            ].filter(Boolean),
            default: null,
        },

        helpText: {
            type: String,
            default: '',
        },

        success: {
            type: Boolean,
            default: false,
        },

        required: {
            type: Boolean,
            default: false,
        },

        placeholder: {
            type: String,
            default: '',
        },

        autocomplete: {
            type: String,
            default: null,
        },

        checkedValue: {
            type: Number,
            default: null,
        },
    },

    computed: {
        inputValue: {
            get() {
                return this.value;
            },

            set(val) {
                this.$emit('input', val);
            },
        },

        /**
         * Check if the input has errors.
         *
         * @returns {boolean}
         */
        hasError() {
            return Boolean(this.errors.length);
        },

        /**
         * Return the error state.
         *
         * @returns {boolean|null}
         */
        state() {
            return this.hasError
                ? false
                : (this.success
                    ? true
                    : null
                );
        },
    },
};
