<template>
    <component
        :is="iconSvg || 'svg'"
        class="app-icon"
        v-bind="$attrs"
        v-on="$listeners"
    />
</template>

<script>
    const req = require.context(
        '../../../assets/icons/svgs',
        false, // Load files recursively. Pass false to skip recursion.
        /.svg$/, // Match files ending with .svg.
    );

    const cache = {};

    export default {
        name: 'app-icon',

        props: {
            name: {
                type: String,
                default: '',
                validator(val) {
                    return req.keys()
                        .includes(`./${ val }.svg`);
                },
            },
        },

        data() {
            return {
                iconSvg: '',
            };
        },

        watch: {
            /**
             * Watch name prop change.
             *
             * @param {string} val
             */
            async name(val) {
                if (val) {
                    await this.setCache();
                    this.iconSvg = cache[this.name];
                }
            },
        },

        async created() {
            const cachedEntry = cache[this.name];

            if (cachedEntry) {
                this.iconSvg = cachedEntry;
                return;
            }

            await this.setCache();
            this.iconSvg = cache[this.name];
        },

        methods: {
            /**
             * Set cache and icon.
             */
            async setCache() {
                const { default: icon } = await import(`../../../assets/icons/svgs/${ this.name }.svg?inline`);

                cache[this.name] = {
                    name: `${ this.name }-icon`,
                    ...icon,
                };
            },
        },
    };
</script>

<style scoped>
    svg {
        display: inline-block;
        width: 1em;
        height: 1em;
        stroke-width: 0;
        color: inherit;
        stroke: currentColor;
        fill: currentColor;
    }
</style>
