<template>
    <nav class="skip-links" ref="focusTarget" aria-label="Skip links">
        <div class="actions">
            <div class="action-item">
                <b-button
                    variant="link"
                    href="#main-content"
                    size="sm"
                    v-test:mainBtn
                >
                    Skip to Main Content
                </b-button>
            </div>
            <div class="action-item">
                <b-button
                    variant="link"
                    href="#site-footer"
                    size="sm"
                    v-test:footerBtn
                >
                    Skip to Footer
                </b-button>
            </div>
        </div>
    </nav>
</template>

<script>
    export default {
        name: 'skip-links',

    };
</script>

<style lang="scss" scoped>
    .skip-links {
        position: absolute;
        top: 0;
        background-color: secondary-palette(7);
        z-index: $zindex-popover;
        transform: translateY(-200%);
        @include transitions-enabled {
            transition: transform 0.5s;
        }

        &:focus-within {
            transform: translateY(0%);
        }
    }

    .actions {
        display: flex;
        margin: 0 -20px;
        padding: 20px;
    }

    .action-item {
        padding-left: 20px;
        padding-right: 20px;
    }
</style>
