export default {
    /**
     * Fetch all stores from the API.
     *
     * @returns {Promise<object>}
     */
    async getStores() {
        const { default: query } = await import('../../queries/stores.graphql');

        return this.$strapi.query(query);
    },
};
