<template>
    <form
        class="newsletter-signup-form"
        @submit.prevent="handleSubmit"
        v-test:form
    >
        <itsu-errors
            v-if="states.errors.length"
            :errors="states.errors"
        />

        <app-form-input
            :placeholder="$snippet('newsletter_email_placeholder', 'email')"
            name="signup"
            :label="$snippet('newsletter_button_text', 'subscribe')"
            :button-text="$snippet('newsletter_button_text', 'subscribe')"
            :success="states.success"
            :disabled="states.submitting"
            required
            autocomplete="email"
            v-model="formData.email"
        />
    </form>
</template>

<script>
    import AppFormInput from '../AppFormInput/AppFormInput';
    import { mapActions } from 'vuex';
    import { ITSU_NEWSLETTER_CTC_ID_COOKIE_NAME } from '../../../plugins/constants';
    import ItsuErrors from '../ItsuErrors/ItsuErrors';

    export default {
        name: 'newsletter-signup-form',

        components: { ItsuErrors, AppFormInput },

        data() {
            return {
                formData: {
                    email: '',
                },
                states: {
                    submitting: false,
                    success: false,
                    errors: [],
                },
            };
        },

        methods: {
            ...mapActions('atreemo/newsletter', [
                'signup',
            ]),

            /**
             * Handle the form submission.
             */
            async handleSubmit() {
                this.states.errors = [];
                this.states.success = false;
                this.states.submitting = true;

                try {
                    const { id } = await this.signup(this.formData);

                    this.$cookies.set(ITSU_NEWSLETTER_CTC_ID_COOKIE_NAME, id);

                    this.states.success = true;

                    this.$router.push(
                        this.$snippet('newsletter_preferences_page_link'),
                    );
                } catch (e) {
                    this.states.errors = ['sorry, there was an issue adding you to the mailing list'];
                } finally {
                    this.states.submitting = false;
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    ::v-deep {
        .form-control.form-control {
            background-color: secondary-palette(7);
            border-color: secondary-palette(7);
        }

        .form-control.form-control::placeholder {
            color: secondary-palette(5);
        }

        .label {
            position: absolute;
            width: 1px;
            height: 1px;
            padding: 0;
            margin: -1px;
            overflow: hidden;
            clip: rect(0, 0, 0, 0);
            white-space: nowrap;
            border: 0;
        }
    }
</style>
