export default {
    /**
     * Fetch blogs from the API.
     *
     * @param {object} context
     * @param {object} payload
     * @param {number} payload.page
     * @param {number} payload.pageSize
     *
     * @returns {object}
     */
    async getBlogPosts(context, { page = 1, pageSize = 9 }) {
        const { default: query } = await import('../../queries/blog-posts.graphql');

        const { blogPosts } = await this.$strapi.query(query, {
            page,
            pageSize,
        });

        const { formatBlogPosts } = await import('../../lib/blog-posts');

        return {
            blogPosts: {
                data: formatBlogPosts(this, blogPosts?.data),
                meta: blogPosts.meta,
            },
        };
    },

    /**
     * Fetch the recent blog posts from the api, excluding the id provided.
     *
     * @param {object} context
     * @param {object} payload
     * @param {number|string} payload.exclude
     *
     * @returns {Promise<{posts: Array}>}
     */
    async fetchRecentBlogPosts(context, { exclude }) {
        const { default: query } = await import('../../queries/blog/recent-blog-posts.graphql');

        const { blogPosts } = await this.$strapi.query(query, {
            exclude,
        });

        const { formatBlogPosts } = await import('../../lib/blog-posts');

        return {
            posts: formatBlogPosts(this, blogPosts?.data),
        };
    },
};
