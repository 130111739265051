<template>
    <footer class="app-footer">
        <b-container>
            <b-row class="footer-row">
                <b-col
                    class="newsletter-sign-up-column"
                    sm="12"
                    xl="4"
                >
                    <footer-newsletter-signup />
                </b-col>
                <b-col
                    class="groups-column"
                    xl="7"
                    offset-xl="1"
                >
                    <div class="groups-container">
                        <nav
                            v-for="group in footerMainNavigation"
                            :key="group.name"
                            class="group"
                            v-test:groupColumn
                            :aria-label="group.name"
                        >
                            <div class="group-name">
                                {{ group.name }}
                            </div>
                            <ul class="group-links">
                                <li
                                    v-for="item in group.items"
                                    :key="item.text"
                                    class="link-item"
                                    v-test:linkItem
                                >
                                    <b-link v-bind="item.props">
                                        <app-icon
                                            v-if="item.icon"
                                            :name="item.icon"
                                            class="social-icon"
                                            v-test:socialIcon
                                        />
                                        {{ item.text }}
                                    </b-link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </b-col>
                <b-col>
                    <b-row class="legal-container">
                        <hr />
                        <b-col cols="12" xl="7" offset-xl="1">
                            <nav aria-label="Legal links">
                                <ul class="legal-links-container" v-test:legalLinksContainer>
                                    <li
                                        v-for="item in footerLinksNavigation.items"
                                        :key="item.text"
                                        class="link-item legal-link"
                                        v-test:legalLink
                                    >
                                        <b-link v-bind="item.props">
                                            {{ item.text }}
                                        </b-link>
                                    </li>
                                    <li class="link-item legal-link">
                                        <b-link
                                            @click="openPrivacyChoicesModal"
                                            class="iubenda-cs-preferences-link"
                                            v-test:privacyChoicesLink
                                        >
                                            {{ $snippet('your_privacy_choices', 'Your Privacy Choices') }}
                                        </b-link>
                                    </li>
                                </ul>
                            </nav>
                        </b-col>
                        <b-col cols="12" xl="4">
                            <div class="copyright">
                                Copyright © {{ currentYear }}. itsu — All rights reserved.
                            </div>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-container>
    </footer>
</template>

<script>
    import TwitterLogo from '../../../../resources/assets/icons/svgs/twitter.svg?inline';
    import FacebookLogo from '../../../../resources/assets/icons/svgs/facebook.svg?inline';
    import InstagramLogo from '../../../../resources/assets/icons/svgs/instagram.svg?inline';
    import LinkedinLogo from '../../../../resources/assets/icons/svgs/linkedin.svg?inline';
    import WorldLogo from '../../../../resources/assets/icons/svgs/world.svg?inline';
    import dayjs from 'dayjs';
    import FooterNewsletterSignup from '../FooterNewsletterSignup/FooterNewsletterSignup';
    import { mapState } from 'vuex';
    import { BLink } from 'bootstrap-vue';

    export default {
        name: 'app-footer',

        components: {
            FooterNewsletterSignup,
            BLink,
            TwitterLogo,
            FacebookLogo,
            InstagramLogo,
            LinkedinLogo,
            WorldLogo,
        },

        computed: {
            ...mapState('navigation', [
                'footerMainNavigation',
                'footerLinksNavigation',
            ]),

            /**
             * Get current year.
             *
             * @returns {number}
             */
            currentYear() {
                return dayjs().year();
            },
        },

        methods: {
            /**
             * Manually open the Privacy Choices modal. This is required when performing internal route changes.
             * Without it, the modal will only open when the page is refreshed.
             */
            openPrivacyChoicesModal() {
                window?._iub?.cs?.ui?.openPreferences();
            },
        },
    };
</script>

<style lang="scss" scoped>
    .app-footer {
        position: relative;
        padding: 25px;
    }

    .newsletter-sign-up-column {
        margin-bottom: 45px;
        display: flex;
        align-items: center;
    }

    .newsletter-sign-up-column:before {
        @include media-breakpoint-up(xl) {
            content: '';
            height: 135%;
            position: absolute;
            right: 0;
            border-left: solid 1px additional-palette(1);
        }
    }

    .groups-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @include media-breakpoint-up(xl) {
            width: 85%;
        }
    }

    .group {
        padding-top: 5px;
        width: 50%;

        @include media-breakpoint-up(md) {
            width: auto;
        }
    }

    .group-name {
        font-size: rem(17px);
        font-weight: 300;
        margin-bottom: 10px;
    }

    .group-links {
        padding-left: 0;
    }

    .link-item {
        list-style: none;
        padding: 6px 0;
        font-weight: 300;
        font-size: rem(15px);

        a {
            color: $black;

            &:hover {
                text-decoration: none;
                color: primary-palette(1);
            }
        }

        svg:hover {
            fill: primary-palette(1);
        }
    }

    .social-icon {
        margin-right: 6px;
        width: 20px;
        height: 20px;
    }

    hr {
        width: 100%;
        border-top: solid 1px additional-palette(1);
        margin-top: 0;

        @include media-breakpoint-only(xl) {
            display: none;
            margin-top: 1rem;
        }
    }

    .legal-container {
        @include media-breakpoint-up(xl) {
            display: flex;
            justify-content: space-around;
            flex-direction: row-reverse;
            align-items: center;
        }
    }

    .legal-links-container {
        display: flex;
        justify-content: space-between;
        padding-left: 0;
        flex-wrap: wrap;

        @include media-breakpoint-up(lg) {
            flex-wrap: nowrap;
            flex-grow: 1;
            justify-content: space-between;
        }

        @include media-breakpoint-up(xl) {
            width: 85%;
            margin-bottom: 0;
        }
    }

    .legal-link {
        font-size: rem(13px);
        width: 50%;

        @include media-breakpoint-up(lg) {
            width: auto;
        }

        a {
            color: secondary-palette(5);
        }
    }

    .copyright {
        font-size: rem(13px);
        color: secondary-palette(6);
    }
</style>
