<template>
    <fade-up-animation :element="() => $refs.section">
        <section class="patterned-hero-section" ref="section">
            <div class="content-container">
                <b-container>
                    <mobile-gutter>
                        <h1 v-test:title>{{ title }}</h1>

                        <div class="content">
                            <slot>
                                <markdown-content
                                    v-if="content"
                                    :content="content"
                                />
                            </slot>
                        </div>
                    </mobile-gutter>
                </b-container>
            </div>

            <lazy-background
                class="pattern-container"
                src="../../../assets/images/svgs/itsu-pattern.svg"
                alt="Itsu pattern"
                v-test:backgroundPattern
            />
        </section>
    </fade-up-animation>
</template>

<script>
    import MarkdownContent from '../../utils/MarkdownContent/MarkdownContent';

    export default {
        name: 'patterned-hero-section',

        components: { MarkdownContent },

        props: {
            title: {
                type: String,
                required: true,
            },

            content: {
                type: String,
                default: '',
            },
        },
    };
</script>

<style lang="scss" scoped>
    .patterned-hero-section {
        text-align: center;
    }

    .content-container {
        padding: 120px 0 65px;

        @include media-breakpoint-up(md) {
            padding-bottom: 30px;
        }
    }

    h1 {
        color: primary-palette(1);
        margin-bottom: rem(40px);

        @include media-breakpoint-up(md) {
            margin-bottom: rem(65px);
        }
    }

    .content {
        font-size: rem(24px);
        max-width: 980px;
        margin: 0 auto;

        @include media-breakpoint-up(md) {
            font-size: rem(35px);
        }
    }

    ::v-deep {
        blockquote {
            font-weight: bold;

            &:last-child {
                margin: 0;
            }

            p:last-child:not(:first-child) {
                font-weight: 300;
                color: primary-palette(1);
                font-size: rem(20px);
                margin-top: rem(27px);

                @include media-breakpoint-up(md) {
                    margin-top: rem(45px);
                    font-size: rem(24px);
                }
            }
        }
    }

    ::v-deep p:last-child {
        margin-bottom: 0;
    }

    .lazy-background.pattern-container {
        height: 259px;
        background-position: center center;
        background-repeat: repeat-x;
        background-size: auto 100%;
    }
</style>
