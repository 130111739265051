import Vue from 'vue';

/**
 * Export any constants in this file.
 *
 * Globally accessible constants can be provided via the `constants`
 * export, or individual constants can be exported.
 *
 * Global exports can be accessed in Vue context via `this.$constants`.
 *
 */

export const ITSU_VARIANT_DEFAULT = 'white';

export const ITSU_VARIANTS = [
    ITSU_VARIANT_DEFAULT,
    'grey',
    'blue',
    'pink',
    'yellow',
];

export const JOBS_PAGE_CAROUSEL_BREAKPOINTS = {
    0: {
        slidesPerView: 1,
    },
    576: {
        slidesPerView: 1.75,
    },
    768: {
        slidesPerView: 2.2,
    },
    992: {
        slidesPerView: 3.2,
    },
    1377: {
        slidesPerView: 4,
    },
};

export const ITSU_NEWSLETTER_CTC_ID_COOKIE_NAME = 'itsu_insider_ctc_id';

const constants = {};

Vue.prototype.$constants = constants;

export default constants;
